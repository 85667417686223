import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Image } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Über | Qyvyfiy Autozentrum
			</title>
			<meta name={"description"} content={"Qyvyfiy Autozentrum Centre bietet eine breite Palette von Dienstleistungen an, die verschiedene Aspekte der Fahrzeugwartung, des Tunings und der Individualisierung abdecken."} />
			<meta property={"og:title"} content={"Über | Qyvyfiy Autozentrum"} />
			<meta property={"og:description"} content={"Qyvyfiy Autozentrum Centre bietet eine breite Palette von Dienstleistungen an, die verschiedene Aspekte der Fahrzeugwartung, des Tunings und der Individualisierung abdecken."} />
			<meta property={"og:image"} content={"https://qyvyfiy.com/img/home-1.jpeg"} />
			<link rel={"shortcut icon"} href={"https://qyvyfiy.com/img/icon.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://qyvyfiy.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://qyvyfiy.com/img/icon.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://qyvyfiy.com/img/icon.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://qyvyfiy.com/img/icon.png"} />
			<meta name={"msapplication-TileImage"} content={"https://qyvyfiy.com/img/icon.png"} />
			<meta name={"msapplication-TileColor"} content={"https://qyvyfiy.com/img/icon.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="50px 0 50px 0" background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/643d8e6ebf72b300207d7233/images/16447966_v960-ning-05.jpg?v=2023-05-16T18:45:46.530Z) 0% 0% /cover repeat scroll padding-box" md-padding="80px 0 90px 0" quarkly-title="Hero-9">
			<Box
				min-width="100px"
				min-height="100px"
				lg-width="100%"
				display="flex"
				flex-direction="column"
				align-items="center"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="--headline1"
					sm-font="normal 700 42px/1.2 --fontFamily-sans"
					padding="0px 200px 0px 200px"
					text-align="center"
					color="--darkL1"
					lg-padding="0px 0 0px 0"
					as="h1"
				>
					Über uns
				</Text>
				<Text
					margin="0px 0px 50px 0px"
					font="--lead"
					color="#50555a"
					padding="0px 200px 0px 200px"
					text-align="center"
					lg-padding="0px 0 0px 0"
				>
					Qyvyfiy Autozentrum Centre bietet eine breite Palette von Dienstleistungen an, die verschiedene Aspekte der Fahrzeugwartung, des Tunings und der Individualisierung abdecken. Hier finden Sie eine genauere Beschreibung unserer Dienstleistungen:
				</Text>
				<Button
					margin="0px 15px 0px 0px"
					padding="12px 28px 12px 28px"
					background="#3f24d8"
					border-radius="8px"
					font="normal 400 17px/1.5 --fontFamily-sans"
					sm-margin="0px 0 15px 0px"
					transition="background-color 0.2s ease 0s"
					border-width="2px"
					border-style="solid"
					border-color="#3f24d8"
					hover-background="rgba(63, 36, 216, 0)"
					hover-color="--darkL1"
					href="/kontakt"
					type="link"
					text-decoration-line="initial"
					align-self="center"
				>
					Kontaktieren Sie uns
				</Button>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" background="#EDF2F6" sm-padding="60px 0 60px 0">
			<Box
				width="100%"
				display="flex"
				flex-direction="column"
				md-width="100%"
				md-align-items="center"
				md-justify-content="center"
				md-text-align="center"
				lg-width="100%"
				margin="0px 0px 56px 0px"
				align-items="center"
			>
				<Text
					margin="0px 0px 16px 0px"
					font="--headline2"
					color="--dark"
					md-text-align="center"
					sm-font="normal 700 32px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
				>
					Wir sind eine Gemeinschaft von Autoliebhabern und Fachleuten
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					font="--base"
					color="--greyD3"
					md-text-align="center"
					text-align="center"
				>
					Unsere Mitarbeiter sind hochqualifizierte Fachleute, die nicht nur über umfangreiche Erfahrungen in den Bereichen Wartung und Tuning verfügen, sondern auch echte Liebhaber der Automobilkultur sind. Unser Expertenteam aus der Welt des Autotunings ist bereit, Ihnen hervorragende Lösungen zur Verbesserung der Leistung, des Aussehens und des Sounds Ihres Fahrzeugs anzubieten.
				</Text>
			</Box>
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="32px 4%" md-grid-template-columns="1fr">
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Motor- und Fahrwerkstuning
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						- Steigerung der Motorleistung.
						<br />
						- Verbesserung der Motordynamik und des Ansprechverhaltens.
						<br />
						- Einbau von Hochleistungsluftfiltern und Hochleistungsauspuffanlagen.
						<br />
						- Programmierung der elektronischen Motorsteuerung.
						<br />
						- Tieferlegung des Fahrwerks, Austausch von Federbeinen und Federn, Lenkern und Buchsen für maximale Leistung.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Regelmäßige Wartung
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						- Vollständige Fahrzeugdiagnose.
						<br />
						- Öl- und Filterwechsel.
						<br />
						- Überprüfung und Austausch von Bremsbelägen und -scheiben.
						<br />
						- Einstellung und Austausch der Aufhängung.
						<br />
						- Überprüfung und Wartung des Kühlsystems.
						<br />
						- Elektronische Diagnose und Behebung von elektrischen Problemen.
					</Text>
				</Box>
				<Box
					padding="45px 45px"
					lg-padding="45px 30px"
					md-padding="45px 45px"
					background="#FFFFFF"
					border-radius="24px"
					justify-content="flex-start"
					flex-direction="column"
					display="flex"
				>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Individualisierung und Exterieur-Tuning
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						- Individuelle Fahrzeugbeklebung mit Folie.
						<br />
						- Einbau von Aerodynamikgewichten und Carbonteilen.
						<br />
						- Montage von einzigartigen Rädern und Reifen.
						<br />
						- Dekoration des Fahrzeugs mit Vinyl-Aufklebern und Grafiken.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section sm-padding="60px 0 60px 0" padding="65px 0 65px 0" quarkly-title="Images-9">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
			>
				<Box
					width="100%"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					height="auto"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
				>
					<Image
						left={0}
						min-height="100%"
						top={0}
						right={0}
						bottom={0}
						src="https://qyvyfiy.com/img/about-1.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="25%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					width="100%"
					height="auto"
					overflow-y="hidden"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
					overflow-x="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						bottom={0}
						min-height="100%"
						src="https://qyvyfiy.com/img/about2.jpg"
						object-fit="cover"
						width="100%"
						top={0}
						position="absolute"
						display="block"
						left={0}
						right={0}
					/>
				</Box>
			</Box>
			<Box
				lg-align-items="center"
				padding="16px 16px 16px 16px"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="25%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				lg-order="1"
			>
				<Box
					width="100%"
					overflow-x="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 150% 0px"
					height="auto"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						left={0}
						right={0}
						bottom="0px"
						min-height="100%"
						object-fit="cover"
						position="absolute"
						display="block"
						top="auto"
						src="https://qyvyfiy.com/img/about-3.jpeg"
						width="100%"
					/>
				</Box>
			</Box>
			<Box
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-order="1"
				width="25%"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				display="flex"
				flex-direction="column"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					lg-text-align="center"
					sm-margin="30px 0px 0px 0px"
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					md-text-align="left"
				>
					Um einen Termin zu vereinbaren, kontaktieren Sie bitte unser Team per Telefon oder E-Mail. Wir helfen Ihnen gerne weiter!
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});